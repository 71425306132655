@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  color: white;
  font-family: "Creepster", system-ui;
  font-weight: 400;
  font-style: normal;
}
  
button,p {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

input {
  font-family: "Press Start 2P", system-ui;
  color: white;
}

input:focus {
  outline: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}